import React from "react";
import { BigTestimonialCard } from "../ui-components/TestimonialCards";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { ShadowForCurves } from "../styles/shared";

/**
 *
 * Data Component
 *
 */

export type LandingTestimonialCTAType = {
  slug: string;
  companyLogo: IGatsbyImageData;
  companyPersonName: string;
  companyPersonTitle: string;
  title: string;
  excerpt1: string;
  excerpt2: string;
};

const LandingTestimonialCTA = ({
  slug,
  companyLogo,
  title,
  excerpt1,
  excerpt2,
  companyPersonName,
  companyPersonTitle,
}: LandingTestimonialCTAType) => {
  return (
    <div className="div-block hidden lg:flex justify-center z-10">
      <div style={{ ...ShadowForCurves }}>
        <BigTestimonialCard
          headline={title}
          text={excerpt1}
          image={companyLogo}
          jobTitle={companyPersonTitle}
          name={companyPersonName}
          slug={slug}
        >
          <p className="mt-4 md:mt-1 text-xl mb-4">{excerpt2}</p>
        </BigTestimonialCard>
      </div>
    </div>
  );
};

export default LandingTestimonialCTA;
