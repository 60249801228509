import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { DeepNonNullable } from "ts-essentials";
import { ClientLogosDataQuery } from "../../graphqlTypes";
import { mdxBody } from "@utils/mdxUtils";
import { MDXRenderer } from "gatsby-plugin-mdx";

/**
 *
 * Component
 *
 */
const ClientsSection = () => {
  const data = useStaticQuery<DeepNonNullable<ClientLogosDataQuery>>(graphql`
    query ClientLogosData {
      strapiClientLogos {
        childStrapiClientLogosTitle {
          childMdx {
            body
          }
        }
        imageSet {
          images {
            id
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 70, placeholder: NONE)
              }
            }
          }
        }
      }
    }
  `);

  const clients = data.strapiClientLogos.imageSet.images;

  return (
    <div className="div-block mt-10 md:mt-0 md:pt-16 md:pb-20 faded-curves">
      <div className="max-w-full">
        <div className="flex flex-col text-center">
          <div id="join_clients" className="text-center text-white">
            <MDXRenderer>
              {mdxBody(data.strapiClientLogos.childStrapiClientLogosTitle)}
            </MDXRenderer>
          </div>
          {/* Logos */}
          <div className="pb-8 mt-5 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 xl:gap-8 md:mt-8">
            {clients.map(client => (
              <div key={client.id}>
                <GatsbyImage
                  image={client.localFile.childImageSharp.gatsbyImageData}
                  className="h-6 md:h-12 my-4 mx-4"
                  alt={client.alternativeText}
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsSection;
