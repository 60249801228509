import React, { useRef, useState } from "react";
import Form from "../ui-components/Form";
import { mdxBody, MDXProp } from "@utils/mdxUtils";
import { MDXRenderer } from "gatsby-plugin-mdx";
import SpinnerModal from "@ui/Modals/SpinnerModal";
import SignupCompleteModal from "@ui/Modals/SignupCompleteModal";
import ErrorModal from "@ui/Modals/ErrorModal";

export type HeroDataType = {
  title: MDXProp;
  subtitle: MDXProp;
  emailInputPlaceholder: string;
  emailInputButtonText: string;
};

const Hero = ({
  title,
  subtitle,
  emailInputPlaceholder,
  emailInputButtonText,
}: HeroDataType) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const modalWaitRef = useRef();
  const modalSuccessRef = useRef();
  const modalErrorRef = useRef();

  const doSignup = async (email: string) => {
    modalWaitRef.current.openModal({ hideButtons: true });

    const data = new FormData();
    data.append("email", email);
    const response = await fetch("/api/actions/signup/", {
      method: "post",
      body: data,
    });

    modalWaitRef.current.closeModal();

    let responseJson: any;
    try {
      responseJson = await response.json();
    } catch (err) {
      responseJson = { messages: "UNKNOWN_ERROR" };
    }

    if (response.status < 300) {
      modalSuccessRef.current.openModal();
    } else {
      switch (responseJson.messages) {
        case "USER_ALREADY_EXIST":
          setErrorMessage("Email address is already registered.");
          break;
        case "MISSING_EMAIL":
        case "INVALID_EMAIL":
        case "DATABASE_CONFLICT":
          setErrorMessage("Invalid email address.");
          break;
        default:
          setErrorMessage("Unknown error!");
      }
      modalErrorRef.current.openModal();
    }
  };

  return (
    <div className="max-w-full mx-6 md:mx-0 mt-10">
      <div className="flex flex-col items-start items-center text-center md:w-full md:p-20 bg-accuRankerPurple-900">
        <div id="title" className="text-center text-white">
          <MDXRenderer>{mdxBody(title)}</MDXRenderer>
        </div>
        <div
          id="subtitle"
          className="text-center text-white text-xl md:text-3xl mt-4 order-last md:order-none"
        >
          <MDXRenderer>{mdxBody(subtitle)}</MDXRenderer>
        </div>
        {/* Email Form */}
        <div className="mt-10 md:w-8/12 lg:w-6/12">
          <Form
            onClick={doSignup}
            cta={emailInputButtonText}
            placeholder={emailInputPlaceholder}
          />
          <span className="text-white">
            14 days free trial - No credit card needed
          </span>
        </div>
        <SignupCompleteModal ref={modalSuccessRef} />
        <SpinnerModal ref={modalWaitRef}>
          <span className="inline-block align-middle">
            <span className="text-2xl">Please wait...</span>
          </span>
        </SpinnerModal>
        <ErrorModal ref={modalErrorRef}>
          <p className="text-2xl">Something went wrong</p>
          <p>{errorMessage}</p>
        </ErrorModal>
      </div>
    </div>
  );
};

export default Hero;
