import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody, MDXProp } from "@utils/mdxUtils";
import { Link } from "gatsby";

type ValuePropositionItemType = {
  id: number;
  title: string;
  description: string;
  linkTitle: string | null;
  linkUrl: string | null;
};

const ValuePropositionSection = (props: {
  title: MDXProp;
  data: ValuePropositionItemType[];
}) => {
  return (
    <div className="hidden md:block div-block mt-10 md:mt-0 md:pt-10 md:pb-10 faded-curves">
      <div className="flex flex-col text-center">
        <div id="join_clients" className="text-white text-3xl">
          <MDXRenderer>{mdxBody(props.title)}</MDXRenderer>
        </div>
        {/* Items */}
        <div
          className="md:w-3/4 md:mx-auto grid grid-cols-3 grid-rows-2 gap-y-1 gap-x-12"
          style={{
            gridTemplateRows: "minmax(0, auto) minmax(0,auto)",
          }}
        >
          {props.data.map(item => (
            <React.Fragment key={item.id}>
              <div className="row-start-1">
                <p className="my-6 text-lg max-w-xs m-auto leading-6 font-medium text-white">
                  {item.title}
                </p>
              </div>
              <div className="mt-2 text-base max-w-sm m-auto text-gray-400 row-start-2 text-justify">
                {item.description}
                {/*{item.linkTitle && item.linkUrl && (*/}
                {/*  <>*/}
                {/*    {" "}*/}
                {/*    <Link*/}
                {/*      to={item.linkUrl}*/}
                {/*      className="text-accuRankerOrange-500 hover:text-accuRankerOrange-900"*/}
                {/*    >*/}
                {/*      {item.linkTitle}*/}
                {/*    </Link>*/}
                {/*    .*/}
                {/*  </>*/}
                {/*)}*/}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ValuePropositionSection;
