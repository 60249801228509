import React from "react";
import CurvedBelow from "../images/Curves/CurvedLinesBelowData.svg";
import CirclesAbove from "../images/Curves/CirclesAboveData.svg";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import {
  CheckIcon,
  CheckCircleIcon,
  BadgeCheckIcon,
} from "@heroicons/react/outline";

/**
 *
 *    Helpers
 *
 */

const DataTitle = styled.p<{ color: string }>`
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0px;
  color: ${props => `${props.color}`};
`;
const DataSubtitle = styled.p`
  font-size: 180%;
  font-weight: bold;
  color: white;
  min-height: 1.5em;
`;

export type DataSectionType = {
  id: number;
  title: string;
  number: string;
  dataType: string;
};

const DataSection = (props: { data: DataSectionType[] }) => {
  const colors = ["#38B9E2", "#38E2A5", "#82CA69", "#C7DB4E"];

  return (
    <div className="hidden lg:block div-block mt-5">
      {/* <div className="flex flex-col text-center p-20 pb-0"> */}
      <div className="flex flex-col text-center">
        <img
          src={CirclesAbove}
          style={{
            minWidth: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            width: "100%",
            position: "relative",
          }}
          alt="circles_above_data"
        />
        <div
          id="data_between_curves"
          className="grid grid-flow-row grid-cols-4 w-full"
          style={{ padding: "0% 8%" }}
        >
          {props.data.map((value, ix) => {
            return (
              <div className="arrow-data" key={value.id}>
                <DataTitle color={colors[ix]}>
                  {value.title.toUpperCase()}
                </DataTitle>
                {/*<DataSubtitle>*/}
                <BadgeCheckIcon
                  className="w-24 mx-auto mb-4 mt-2 transition hover:scale-110"
                  style={{ color: colors[ix] }}
                />
                {/*</DataSubtitle>*/}
              </div>
            );
          })}
        </div>
        <img
          src={CurvedBelow}
          className="mb-0"
          style={{
            marginBottom: "-2px",
            maxHeight: "100%",
            maxWidth: "100%",
            width: "100%",
            position: "relative",
            height: "100%",
          }}
          alt="curves below data"
        />
      </div>
    </div>
  );
};

export default DataSection;
