import React, { useState } from "react";
import Button from "./Button";

type FormProps = {
  placeholder?: string;
  cta?: string;
  onClick: (inputValue: string) => void;
};

/** Form due to the complexity of onSubmit, value reset etc etc, will not be an actively used component */
/** But mainly used for copy/paste on HOC components */
const Form = ({
  placeholder = "Enter your email",
  cta = "Start trial",
  onClick,
}: FormProps) => {
  const [value, setValue] = useState("");

  return (
    <div className="flex justify-center items-start md:items-center">
      <div className="md:relative flex flex-col items-start md:items-center md:flex-row w-full">
        <form
          onSubmit={event => {
            event.preventDefault();
            onClick(value);
          }}
          className="w-full"
        >
          <input
            value={value}
            onChange={e => setValue(e.target.value)}
            type="email"
            required
            className="w-full m-0 md:m-auto pl-5 pr-10 md:pr-44 rounded-xl z-0 focus:shadow focus:outline-none"
            style={{
              /** Strict to follow Figma Design */
              height: "60px",
            }}
            placeholder={placeholder}
          />
          <div
            className="w-full mt-2 md:mt-0 md:absolute md:w-36"
            /** Strict to follow Figma Design */
            style={{
              top: "6px",
              right: "6px",
            }}
          >
            <Button
              style={{
                /** Strict to follow Figma Design */
                height: "48px",
              }}
              type="submit"
              className="w-full m-auto h-12 disabled:opacity-95 bg-accuRankerOrange-500 hover:bg-accuRankerOrange-900 md:bg-accuRankerPurple-900 md:hover:bg-accuRankerPurple-500"
            >
              {cta}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
