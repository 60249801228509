import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/Hero";
import DataSection from "../components/DataSection";
import ClientsSection from "../components/ClientsSection";
import ValuePropositionSection from "../components/ValuePropositionSection";
import Footer from "../components/FooterSection";
import FeatureSection4 from "../components/FeatureSection4";
import LandingTestimonialCTA from "../components/LandingTestimonialCTA";
import BlogSection from "../components/BlogSection";
import { graphql, useStaticQuery } from "gatsby";
import { DeepNonNullable } from "ts-essentials";
import { LandingPageDataQuery } from "../../graphqlTypes";
import DemoPortrait from "../images/DemoPortrait.png";
import { BookDemoBig } from "@ui/BookDemos";

const IndexPage = () => {
  const data = useStaticQuery<DeepNonNullable<LandingPageDataQuery>>(graphql`
    query LandingPageData {
      strapiFrontpage {
        pageTitle
        hero {
          title
          subtitle
          emailInputPlaceholder
          emailInputButtonText
        }
        childStrapiFrontpageHeroTitle {
          childMdx {
            body
          }
        }
        childStrapiFrontpageHeroSubtitle {
          childMdx {
            body
          }
        }
        numbers {
          id
          title
          number
          dataType
        }
        childStrapiFrontpageValuePropositionTitle {
          childMdx {
            body
          }
        }
        valuePropositions {
          id
          title
          description
          linkTitle
          linkUrl
        }
        features {
          id
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 95, width: 1400, placeholder: BLURRED)
              }
              extension
              publicURL
            }
          }
          featurePoints {
            id
            title
            subtitle
          }
          productPage {
            slug
          }
        }
        customer_case {
          companyLogo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 50, placeholder: BLURRED, layout: FIXED)
              }
            }
          }
          title
          slug
          excerpt1
          excerpt2
          companyPersonName
          companyPersonTitle
        }
      }
    }
  `);

  const {
    pageTitle,
    hero,
    numbers,
    valuePropositions,
    features,
    customer_case,
    childStrapiFrontpageHeroSubtitle: subtitle,
    childStrapiFrontpageHeroTitle: title,
    childStrapiFrontpageValuePropositionTitle: valuePropositionTitle,
  } = data.strapiFrontpage;

  return (
    <Layout>
      {/**
        Overflow-x:hidden is needed
        because features are overflowing on the right
        in the mobile design, but we don't want a scrollbar
        Also, other pages need the overflow (like blog)
        for white background
      **/}
      <div className="overflow-x-hidden">
        {/*<Seo title={pageTitle} />*/}
        <Seo title="AccuRanker 2022" />
        <Hero {...{ ...hero, title, subtitle }} />
        <DataSection data={numbers} />
        <ClientsSection />
        <ValuePropositionSection
          data={valuePropositions}
          title={valuePropositionTitle}
        />
        <FeatureSection4 data={features as any} />
        <LandingTestimonialCTA {...(customer_case as any)} />
        <Footer />
      </div>
    </Layout>
  );
};

export default IndexPage;
