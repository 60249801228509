import React from "react";
import { CheckIcon } from "@heroicons/react/outline";
import ConnectingCurve2 from "../images/Curves/Curve2Features2.svg";
import ConnectingCurveAfter from "../images/Curves/CurvedAfterFeatures.svg";
import straightLines from "../images/Curves/StraigthLines.svg";
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { TAILWIND_DEFAULTS } from "@utils/GlobalConstants";
import { Link } from "gatsby";

/**
 *
 *    Helpers
 *
 */

const Feature = ({
  title,
  image,
  subtitle,
  points,
  productPage,
}: {
  title: string;
  subtitle: string;
  points: {
    title: string;
    subtitle: string;
  }[];
  image: any;
  productPage: {
    slug: string;
  };
}) => {
  return (
    <div className="flex flex-col items-center lg:flex-row flex-grow-0 w-full mt-3 relative lg:px-20 lg:pt-0 lg:pb-20 gap-x-20">
      <div className="w-full relative h-full order-1 lg:w-1/2 lg:order-2">
        {!image.localFile.childImageSharp &&
        image.localFile.extension === "svg" ? (
          <img
            className="h-1/2 left-12 xl:left-40"
            src={image.localFile.publicURL}
            alt={image.alternativeText}
            style={{
              maxWidth: "1200px",
              position: "relative",
            }}
          />
        ) : (
          <GatsbyImage
            imgClassName="h-1/2"
            className="absolute h-1/2 left-12 xl:left-40"
            objectFit="contain"
            style={{
              maxWidth: "1200px",
              position: "relative",
            }}
            key={image.id}
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={image.alternativeText}
          />
        )}
      </div>
      <div className="lg:w-1/2">
        <div className="w-full order-2 lg:order-1">
          <h2 className="text-accuRankerOrange-500 text-3xl font-sans font-normal">
            {title}
          </h2>
          <p
            className="text-white text-xl text-justify mt-1"
            dangerouslySetInnerHTML={{
              __html: subtitle.replace("\n", "<br/>"),
            }}
          />
        </div>
        <div className="mt-6 flex flex-col space-y-5">
          {/* Bullet Element */}
          {points.map((point, ix) => (
            <div className="flex flex-row" key={ix}>
              <div className="bg-accuRankerPurple-500 rounded-full h-14 w-14 flex items-center justify-center">
                <CheckIcon className="p-4 text-white h-14 w-14" />
              </div>
              <div className="ml-5 pt-4">
                <p className="text-white mb-0">{point.title}</p>
                {point.subtitle && (
                  <div className="text-gray-400 text-sm text-justify">
                    {point.subtitle}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        {/*{productPage && (*/}
        {/*  <div className="mt-6">*/}
        {/*    <Link*/}
        {/*      to={"/" + productPage.slug + "/"}*/}
        {/*      className="border-2 rounded-md font-medium text-white border-accuRankerOrange-500 text-accuRankerOrange-500 py-1 px-2 hover:bg-accuRankerOrange-500 hover:text-white transition-color duration-300"*/}
        {/*    >*/}
        {/*      Read more*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

const StraigthLineBackground = styled.div`
  /* lg breakpoint */
  @media (min-width: ${TAILWIND_DEFAULTS.screens.lg}) {
    position: relative;
    background: url(${straightLines});
    background-position: center;
    background-size: 100% auto;
    background-repeat: repeat-y;
  }
`;

type FeatureSectionPointItemType = {
  id: number;
  title: string;
  subtitle: string;
};

type FeatureSectionProductPageType = {
  slug: string;
};

type FeatureSectionItemType = {
  id: number;
  title: string;
  subtitle: string;
  image: any;
  featurePoints: FeatureSectionPointItemType[];
  productPage: FeatureSectionProductPageType;
};

/**
 *
 * Data Component
 *
 */

const FeatureSection = (props: { data: FeatureSectionItemType[] }) => {
  const allFeatures = props.data;

  return (
    <div className="div-block mt-10 md:mt-0 md:pt-0">
      <div className="w-full relative">
        <img
          className="hidden lg:block"
          src={ConnectingCurve2}
          style={{
            width: "100%",
            marginBottom: "-3%",
          }}
          alt="connecting curve image"
        />
      </div>
      <div
        className="flex flex-col justify-around w-full"
        style={{
          marginTop: "2%",
        }}
      >
        <StraigthLineBackground>
          {allFeatures.map((feature, ix: number) => (
            <Feature
              key={ix}
              title={feature.title}
              image={feature.image}
              subtitle={feature.subtitle}
              points={feature.featurePoints}
              productPage={feature.productPage}
            />
          ))}
        </StraigthLineBackground>
      </div>
      <div className="w-full relative" style={{ zIndex: -1 }}>
        <img
          className="hidden lg:block"
          src={ConnectingCurveAfter}
          style={{
            width: "100%",
            marginBottom: "-3%",
          }}
          alt="connecting curve image"
        />
      </div>
    </div>
  );
};

export default FeatureSection;
